.mosaico_personalize {
  will-change: transform;
  width: auto;
  height: auto;
  display: block;
  border-radius: 8px;
}

.div_mosaico_personalize {
  justify-content: right;
  display: flex;
  height: 400px;
}
