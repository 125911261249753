.mosaico {
  top: 0px;
  right: 0px;
  will-change: transform;
  width: 42vmax;
  height: 42vmax;
  display: flex;
  border-radius: 8px;
  margin-right: -5.5px;
}

.div_mosaico {
  justify-content: right;
  display: flex;
  height: 400px;
}
