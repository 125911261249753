.fondo {
  text-align: center;
  text-decoration: none;
  height: auto;
  width: auto;
  display: block;
}

.mosaico {
  will-change: transform;
  width: auto;
  height: 45vmax;
  display: flex;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  margin-right: -4px;
}

.div_mosaico {
  text-align: center;
  text-decoration: none;
  justify-content: right;
  display: flex;
  height: 400px;
}
